// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  maxBreadcrumbs: 50,
  environment: process.env.NEXT_PUBLIC_APP_ENVIRONMENT,
  attachStacktrace: true,
  tracesSampleRate: 0.05,
  release: process.env.NEXT_APP_RELEASE,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb?.category?.startsWith('ui')) {
      const { target } = hint?.event;
      breadcrumb.message =
        target.id || target.dataset.testid
          ? `${target.tagName.toLowerCase()}[@id="${target.id}"][@data-testid="${
              target.dataset.testid
            }"]`
          : breadcrumb.message;
    }
    return breadcrumb;
  },
});
